import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import moment from 'moment'
import { PageLayout } from '../layouts'
import { PageHeader } from '../components'
import * as styles from '../styles/pages/basicTemplate'
import * as base from '../styles/base'
import variables from '../styles/variables'

const propTypes = {
  data: PropTypes.shape({
    frontmatter: PropTypes.shape({
      path: PropTypes.string,
      title: PropTypes.string,
      version: PropTypes.string
    }),

    mdx: PropTypes.object.isRequired
  }).isRequired
}

export default function BasicTemplate({ data }) {
  const { frontmatter, body } = data.mdx

  let versionDate

  if (frontmatter && frontmatter.version) {
    const mmnt = moment(frontmatter.version)
    versionDate = mmnt.format('[on the] Do [of] MMMM YYYY')
  }

  const firstChild = (
    <PageHeader header={frontmatter.header} info={frontmatter.info} />
  )

  return (
    <PageLayout
      navbarProps={{ background: variables.colors.oracle.dark }}
      description={frontmatter.metaDescription}
      firstChild={firstChild}
      sharingTitle={`Aito.ai - ${frontmatter.title}`}
    >
      <Helmet>
        <title>{`Aito.ai - ${frontmatter.title}`}</title>
      </Helmet>
      <styles.Container>
        <base.MdContainer>
          <MDXRenderer version={versionDate}>{body}</MDXRenderer>
        </base.MdContainer>
      </styles.Container>
    </PageLayout>
  )
}

BasicTemplate.propTypes = propTypes

export const pageQuery = graphql`
  query PostPath($id: String!) {
    mdx( id: { eq: $id } ) {
      id
      body
      frontmatter {
        path
        title
        header
        info
        metaDescription
        version
      }
    }
  }
`
